import * as Sentry from '@sentry/remix';
import React, { useState } from 'react';
import * as ReactDOM from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { RemixBrowser } from '@remix-run/react';

import { ClientStyleContext } from './context';
import createEmotionCache, { defaultCache } from './createEmotionCache';

Sentry.init({
  dsn: 'https://1491c4e2de2cdeb2f08f1995ffb6b877@o4507782738542592.ingest.us.sentry.io/4507805307305984',
});

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = useState(defaultCache);

  function reset() {
    setCache(createEmotionCache());
  }

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

const hydrate = () => {
  React.startTransition(() => {
    ReactDOM.hydrateRoot(
      document,
      <ClientCacheProvider>
        <RemixBrowser />
      </ClientCacheProvider>
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
